import { css } from "@emotion/react";
import styled from "@emotion/styled";

export { absolute, flex, flexCol, relative, w100 } from "app/styles/utils";

const columns = ({ col = 2 }) => css`
  grid-template-columns: repeat(${col}, 1fr);
`;

export const gridCol = css`
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, min-content);
`;

export const Grid = styled.div`
  display: grid;
  ${columns};
  grid-gap: var(--gutter);
  justify-content: center;
  width: 100%;
`;

const gridPos = ({ col, row }) => {
  let style = ``;

  if (col && col[0]) {
    const [rStart, rEnd] = col;
    const colStyle = rEnd ? `${rStart} / ${rEnd}` : rStart;
    style += `grid-column: ${colStyle};`;
  }

  if (row && row[0]) {
    const [cStart, cEnd] = row;
    const rowStyle = cEnd ? `${cStart} / ${cEnd}` : cStart;
    style += `grid-row: ${rowStyle};`;
  }

  return style;
};

export const GridItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${gridPos}
  ${({ top }) =>
    top ? `align-items: flex-start;align-self: flex-start;` : ``};
`;

export const pinFootnote = css`
  top: 0;
  left: 0;
`;

export const footnoteGap = css`
  margin-top: 30px;
`;
