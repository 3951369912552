import React, { useEffect } from "react";
import _get from "lodash.get";
import { useMeasure } from "react-use";

import { getGridLayout, isTall } from "app/components/ImageGrid.layouts";

import FootnotesBox from "app/components/FootnotesBox";
import RatioImage from "app/components/RatioImage";

import useBoundingBox from "app/hooks/useBoundingBox";

import * as S from "app/components/ImageGrid.style";

const GridCell = React.forwardRef(({ layout, img, idx, children }, ref) => {
  const col = _get(layout, `cols[${idx}]`);
  const row = _get(layout, `rows[${idx}]`);

  return (
    <S.GridItem
      ref={ref}
      key={idx}
      col={col}
      row={row}
      data-col={`[${col}]`}
      data-row={`[${row}]`}
      data-ratio={img.ratio}
      top={layout.top === idx}
    >
      {children}
    </S.GridItem>
  );
});

export const ImageGridPanel = ({
  items,
  layout: layoutKey,
  showCaptions,
  showCredits,
  setSpacerH,
}) => {
  const [footnotesBoxRef, footnoteBox] = useBoundingBox();
  const [footnoteRef, { height }] = useMeasure();
  const [imageRef, imageBox] = useBoundingBox();
  const footnotes = showCaptions || showCredits;
  const offset = _get(footnoteBox, "bottom", 0) - _get(imageBox, "bottom", 0);

  useEffect(() => {
    if (footnotes && offset > 0) {
      setSpacerH(height);
    }
  }, [setSpacerH, offset, height, footnotes]);

  if (items.length < 1) {
    return <></>;
  }

  const layout = getGridLayout(layoutKey, items);
  const images = items.slice(0, layout.limit);
  const [nextToLast, last] = images.slice(-2);

  const footnotesInCell =
    nextToLast && last && isTall(nextToLast) !== isTall(last);
  const cellPlacement = isTall(nextToLast)
    ? _get(last, "id")
    : _get(nextToLast, "id");

  return (
    <S.Grid col={layout.colCount} limit={layout.limit} data-layout={layoutKey}>
      {images.map((img, idx) => (
        <GridCell
          ref={idx === images.length - 1 ? imageRef : undefined}
          key={idx}
          layout={layout}
          img={img}
          idx={idx}
        >
          <RatioImage
            {...img}
            num={idx + 1}
            total={images.length}
            showCaptions={showCaptions}
          />
          {footnotesInCell && _get(img, "id") === cellPlacement && (
            <div css={S.footnoteGap}>
              <FootnotesBox
                items={items}
                showCaptions={showCaptions}
                showCredits={showCredits}
              />
            </div>
          )}
        </GridCell>
      ))}
      {!footnotesInCell && (
        <S.GridItem css={S.relative}>
          <div ref={footnotesBoxRef} css={[S.absolute, S.pinFootnote, S.w100]}>
            <div ref={footnoteRef}>
              <FootnotesBox
                items={items}
                showCaptions={showCaptions}
                showCredits={showCredits}
              />
            </div>
          </div>
        </S.GridItem>
      )}
    </S.Grid>
  );
};
