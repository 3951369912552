import React, { useState } from "react";
// import _get from "lodash.get";

export const PageContext = React.createContext({});

const PageProvider = ({ children }) => {
  const [pageData, setPageData] = useState([]);

  return (
    <PageContext.Provider value={{ pageData, setPageData }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
