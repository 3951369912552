import React, { useContext } from "react";
import _get from "lodash.get";
import { motion, AnimatePresence } from "framer-motion";

import Button from "app/components/Button";
import { ButtonBase } from "app/components/Button.style";
import Image from "app/components/Image";

import { PageContext } from "app/components/PageProvider";

import * as S from "app/components/DiagramList.style";

const useActive = ({ entryId, diagrams }) => {
  const { pageData, setPageData } = useContext(PageContext);
  const state = pageData.find((pg) => entryId === _get(pg, "id"));
  const activeIdx = _get(state, "active") || 0;
  const active = _get(diagrams, `[${activeIdx}].image`);

  const updatePage = (data) => {
    setPageData([
      ...pageData.filter(({ id }) => id !== entryId),
      {
        id: entryId,
        ...data,
      },
    ]);
  };

  return {
    active,
    activeIdx,
    pageData,
    setPageData: updatePage,
  };
};

export const Diagram = ({ entryId, diagrams }) => {
  const { active, activeIdx } = useActive({ entryId, diagrams });

  return (
    <div css={[S.imageFrame, S.relative]}>
      <AnimatePresence>
        <motion.div
          key={`diagram-${activeIdx}`}
          css={[S.absolute, S.imageBox, S.w100]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
        >
          <Image
            image={active}
            imgStyle={{ objectFit: "contain" }}
            style={{ width: "100%", height: "100%" }}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const DiagramList = ({ entry }) => {
  const entryId = _get(entry, "id");
  const panelData = _get(entry, "sidePanel[0]", {});
  const diagrams = _get(panelData, "diagrams") || [];
  const listStyle = _get(panelData, "listStyle", "single");
  const { activeIdx, setPageData } = useActive({ entryId, diagrams });

  return (
    <div
      css={[
        S.flex,
        S.flexCol,
        S.topGap,
        listStyle === "double" ? S.double : S.single,
      ]}
    >
      {diagrams.map(
        ({ id, label }, idx) =>
          !!idx && (
            <Button
              key={id}
              Component={ButtonBase}
              outlined={true}
              invert={activeIdx === idx}
              onClick={() => {
                setPageData({
                  active: idx,
                });
              }}
              css={S.chartBtn}
            >
              {listStyle === "single" && <span css={S.counter}>{idx}</span>}
              {label}
            </Button>
          )
      )}
    </div>
  );
};

export default DiagramList;
