import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconSlideshow = ({ color, bgColor, ...props }) => (
  <SVGIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <g stroke={color} strokeWidth="4">
        <rect width="41.792" height="31.709" x="3.216" y="9.178" rx="7"></rect>
        <rect
          style={{ fill: bgColor }}
          width="41.792"
          height="31.709"
          x="14.151"
          y="19.121"
          rx="7"
        ></rect>
      </g>
    </g>
  </SVGIcon>
);

IconSlideshow.defaultProps = {
  color: "currentColor",
  bgColor: "var(--graydark)",
  width: "60",
  height: "60",
  viewBox: "0 0 60 60",
};

export default IconSlideshow;
