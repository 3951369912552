import { css } from "@emotion/react";
// import styled from "@emotion/styled";
export {
  absolute,
  alignCenter,
  flex,
  flexCol,
  relative,
  w100,
  justifyCenter,
} from "app/styles/utils";

export const imageFrame = css`
  height: 0;
  padding-top: calc(1920 / 1335 * 100%);
`;

export const imageBox = css`
  top: 0;
  left: 0;
`;

export const topGap = css`
  margin-top: 30px;
`;

export const single = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 30px;
`;

export const double = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-gap: 15px;
`;

export const counter = css`
  font-size: 26px;
  line-height: 1.1;
  margin-right: 16px;
`;

export const chartBtn = css`
  padding: 20px 26px;
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  font-family: inherit;
  text-align: left;
`;
