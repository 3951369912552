import styled from "@emotion/styled";
import { fontWeights, rem } from "app/styles/utils";

export const List = styled.ol`
  counter-reset: credits-counter;
  list-style: none;
  padding: 0;
  padding-left: 30px;
  margin: 0;

  li {
    counter-increment: credits-counter;
    position: relative;
  }

  li::before {
    position: absolute;
    left: ${rem(-90)};
    top: 0.1em;
    font-weight: ${fontWeights.medium};
    content: counter(credits-counter, decimal-leading-zero);
  }

  li + li {
    margin-top: ${rem(30)};
  }
`;

export const Num = styled.span`
  font-weight: ${fontWeights.medium};
  padding-right: ${rem(20)};
`;
