import { useEffect, useState } from "react";
import { useWindowSize, useWindowScroll } from "react-use";
// import _get from "lodash.get";

const useActiveSection = ({ scroll, scrollRef }) => {
  const { height: windowH } = useWindowSize({ height: 750 });
  const [sectionIdx, setSectionIdx] = useState(0);

  useEffect(() => {
    const unsubscribe = scroll.onChange((val) => {
      const sections = [
        ...scrollRef.current.querySelectorAll(`[data-section]`),
      ];
      const focused = sections.find((el) => el.offsetTop >= val + windowH / 2);

      if (focused) {
        setSectionIdx(Math.max(parseInt(focused.dataset.section, 10) - 1, 0));
      } else {
        setSectionIdx(Math.max(sections.length - 1, 0));
      }
    });

    return unsubscribe;
  }, [scroll, scrollRef, windowH]);

  return sectionIdx;
};

export const useActiveSectionWindow = () => {
  const { y } = useWindowScroll();
  const { height: windowH } = useWindowSize({ height: 750 });
  const [sectionIdx, setSectionIdx] = useState(0);

  useEffect(() => {
    if (typeof window === "undefined") {
      setSectionIdx(0);
      return;
    }

    const sections = [...document.querySelectorAll(`[data-section]`)];
    const focused = sections.find((el) => el.offsetTop >= y + windowH / 4);

    if (focused) {
      setSectionIdx(Math.max(parseInt(focused.dataset.section, 10) - 1, 0));
    } else {
      setSectionIdx(Math.max(sections.length - 1, 0));
    }
  }, [y, windowH, setSectionIdx]);

  return sectionIdx;
};

export default useActiveSection;
