import React from "react";

import * as S from "app/components/RatioBox.style";

export { ratioValues } from "app/components/RatioBox.style";

const RatioBox = ({ ratio, children, bgColor, ...props }) => (
  <S.RatioBox ratio={ratio} {...props}>
    <S.RatioBoxInner bgColor={bgColor}>{children}</S.RatioBoxInner>
  </S.RatioBox>
);

export default RatioBox;
