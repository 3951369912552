import { css } from "@emotion/react";

import { gray10, ivory } from "app/styles/utils";

export {
  cover,
  flex,
  flexCenterAll,
  gutters,
  relative,
  square,
} from "app/styles/utils";

export const pinBotRight = css`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export const btnSize = css`
  width: 33px;
  height: 33px;
  background: var(--graydark);
  border-radius: 100%;
`;

export const icon = css`
  width: 28px;
  height: 28px;
  color: var(--ivory);
`;

export const invert = css`
  --ivory: ${gray10};
  --graydark: ${ivory};
`;
