import { css } from "@emotion/react";
import { fontWeights } from "app/styles/utils";

export { cover, contain } from "app/styles/utils";

export const number = css`
  position: absolute;
  left: calc(100% + 4px);
  top: 0;
  z-index: 2;
  font-weight: ${fontWeights.thin};
  font-size: 12px;
  line-height: 1.2;
`;
