import React from "react";
// import _get from "lodash.get";

import Typography from "app/components/Typography";
import { RedactorSimpleText } from "app/components/RedactorText";

import * as S from "app/components/CaptionsList.style";

// const number = (idx) => `${idx}`.padStart(2, "0");

export const CaptionsList = ({ list, field, ...props }) => {
  if (!Array.isArray(list) || list.length < 1) {
    return <></>;
  }

  return (
    <S.List>
      {list.map((text, idx) => (
        <Typography key={idx} as="li" variant="citation" {...props}>
          <RedactorSimpleText html={text} />
        </Typography>
      ))}
    </S.List>
  );
};

export default CaptionsList;
