import React from "react";
import _get from "lodash.get";

import CaptionsList from "app/components/CaptionsList";
import InfoPopup from "app/components/infoboxes/InfoPopup";

export const CreditsBox = ({ className, items, label }) => {
  const credits = items
    .map((figure) => _get(figure, `figure[0].credit`))
    .filter((f) => f);

  if (!Array.isArray(credits) || !credits.length) {
    return <></>;
  }

  return (
    <InfoPopup className={className} label={label}>
      <CaptionsList list={credits} />
    </InfoPopup>
  );
};

CreditsBox.defaultProps = {
  label: "Credits",
};

export default CreditsBox;
