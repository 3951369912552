import { css } from "@emotion/react";

export {
  absolute,
  gutters,
  flex,
  flexCol,
  relative,
  w50,
  w100,
  justifyCenter,
  justifyBetween,
} from "app/styles/utils";

export const detailGlobal = css`
  :root {
    --row-gap: 0;
    --top-gap: 90px;
  }
`;

export const anchorLink = css`
  top: calc((var(--nav-height) + 20px) * -1);
`;

export const title = css`
  white-space: pre-line;
  margin-bottom: 90px;
`;

export const sectionTitle = css`
  position: relative;
  padding: 60px 15px 15px 15px;
  font-size: 28px;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ivory);
    border-radius: 7px;
    opacity: 0.08;
  }
`;

export const sectionGap = css`
  padding-top: 60px;
  padding-bottom: 90px;
`;

export const wrap = css`
  grid-area: content;
  overflow: hidden;
`;

export const gridContent = css`
  grid-area: content;
`;

export const padTopGap = css`
  padding-top: var(--top-gap);
`;

export const scrollBox = css`
  padding-top: var(--top-gap);
  height: calc(100vh - var(--nav-height));
  overflow-y: scroll;
  overflow-x: visible;
`;

export const sidePanel = css`
  margin: 0;
  padding: 120px 30px;
`;

export const textPanel = css`
  padding: 20px 30px 20px 15px;
  max-width: 750px;
`;

export const imagePanel = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  margin: 0;
  padding: 30px;
`;

export const sectionMargin = css`
  margin: 80px 0 40px;
`;
