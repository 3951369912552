import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, percentF } from "app/styles/utils";
import get from "lodash.get";

export const r1x1 = css`
  padding-top: 100%;
`;

export const h2x3 = css`
  width: ${rem(1500)};
  padding-top: ${percentF(1000 / 1500)};
`;

export const h3x4 = css`
  width: ${rem(1300)};
  padding-top: ${percentF(1000 / 1300)};
`;

export const h4x5 = css`
  width: ${rem(1250)};
  padding-top: ${percentF(1000 / 1250)};
`;

export const v3x2 = css`
  width: ${rem(1000)};
  padding-top: ${percentF(1500 / 1000)};
`;

export const v4x3 = css`
  width: ${rem(1000)};
  padding-top: ${percentF(1300 / 1000)};
`;

export const v5x4 = css`
  width: ${rem(1000)};
  padding-top: ${percentF(1250 / 1000)};
`;

const ratios = {
  "1x1": r1x1,
  "4x3": v4x3,
  "3x2": v3x2,
  "5x4": v5x4,
  "2x3": h2x3,
  "3x4": h3x4,
  "4x5": h4x5,
};

export const ratioValues = {
  "1x1": 1,
  "4x3": 1300 / 1000,
  "3x2": 1500 / 1000,
  "5x4": 1500 / 1000,
  "2x3": 1000 / 1500,
  "3x4": 1000 / 1300,
  "4x5": 1000 / 1250,
};

const getRatio = ({ ratio }) => (ratio && ratios[ratio]) || ``;

const bgColors = {
  ivory: `var(--ivory)`,
  black: `#000`,
  grayDark: `var(--graydark)`,
};

export const RatioBox = styled.div`
  position: relative;
  display: block;
  height: 0;
  ${getRatio};
  ${({ fit }) => (fit && `width: 100%`) || ``};
  max-width: 100%;
`;

export const RatioBoxInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ bgColor }) =>
    `background: ${get(bgColors, `${bgColor}`, bgColors.black)}`};
`;
