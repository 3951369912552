import React from "react";
import _get from "lodash.get";

import DiagramList, { Diagram } from "app/components/DiagramList";
import { ImageGridPanel } from "app/components/ImageGrid";
import PageProvider from "app/components/PageProvider";
import RedactorText from "app/components/RedactorText";

import ThumbnailGallery from "app/components/ThumbnailGallery";

import * as S from "app/components/DetailPage.style";

const sidePanels = {
  fixedGrid: ImageGridPanel,
  diagramList: Diagram,
  thumbnailGallery: ThumbnailGallery,
};

const mainPanels = {
  diagramList: DiagramList,
};

const SubPage = ({ entry, setSpacerH }) => {
  const content = _get(entry, "mainContent");
  const panelType = _get(entry, `sidePanel[0].typeHandle`);
  const MainPanel = _get(mainPanels, panelType);
  const SidePanel = _get(sidePanels, _get(entry, `sidePanel[0].typeHandle`));

  return (
    <PageProvider>
      <div
        css={[S.flex, S.w100, S.justifyBetween]}
        data-panel={_get(entry, "id")}
      >
        <div css={[S.flex, S.flexCol, S.justifyCenter, S.w50, S.textPanel]}>
          {content && (
            <RedactorText
              html={content}
              infoBoxes={_get(entry, "infoBoxes", [])}
            />
          )}
          {MainPanel && <MainPanel entry={entry} />}
        </div>
        <div css={[S.w50, S.imagePanel]}>
          {SidePanel && (
            <SidePanel
              {..._get(entry, "sidePanel[0]", {})}
              entryId={_get(entry, "id")}
              setSpacerH={setSpacerH}
            />
          )}
        </div>
      </div>
    </PageProvider>
  );
};

const MemoizedSubPage = React.memo(
  SubPage,
  (prev, next) => _get(prev, "id") === _get(next, "id")
);

export default MemoizedSubPage;
