import { css } from "@emotion/react";

export const footnoteBox = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
`;

export const topGap = css`
  margin-top: 10px;
`;
