import { css } from "@emotion/react";

export {
  alignCenter,
  border,
  borderRound,
  flex,
  flexCol,
  relative,
  textButton,
  w100,
} from "app/styles/utils";

export const thumbList = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const buttonBox = css`
  position: relative;
  display: block;
  background: none;
  border: 0;
  text-align: left;
  color: var(--ivory);
  border-bottom: 1px solid var(--ivory);
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    top: 16px;
    bottom: 16px;
    width: 1px;
    background-color: var(--ivory);
  }
`;

export const p2 = css`
  padding: 8px;
`;

export const p4 = css`
  padding: 16px;
`;

export const slideshowIcon = css`
  width: 20px;
  height: auto;
  margin-left: auto;
  margin-right: 3px;
`;
