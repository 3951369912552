import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconPlus = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M42.005 26.799c.318 0 .6.071.837.202.236.13.426.32.557.556.13.237.202.52.202.837v12.01h11.692c.317 0 .6.072.837.202.236.131.426.32.556.557.131.236.202.52.202.837s-.071.6-.202.837c-.13.236-.32.426-.556.557-.236.13-.52.202-.837.202L43.6 43.595v12.01c0 .318-.072.601-.202.838-.13.236-.32.426-.557.556-.236.13-.52.202-.837.202s-.6-.071-.837-.202a1.407 1.407 0 01-.557-.556 1.718 1.718 0 01-.201-.837l-.001-12.011H28.707c-.317 0-.6-.07-.837-.201a1.407 1.407 0 01-.556-.557c-.131-.236-.202-.52-.202-.837s.071-.6.202-.837c.13-.236.32-.426.556-.557.236-.13.52-.202.837-.202H40.41v-12.01c0-.317.072-.6.202-.837.131-.236.32-.426.557-.556.236-.13.52-.202.837-.202z"
    ></path>
  </SVGIcon>
);

IconPlus.defaultProps = {
  color: "currentColor",
};

export default IconPlus;
