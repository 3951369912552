import React from "react";
import _get from "lodash.get";

import CaptionsList from "app/components/CaptionsList";

import CreditsBox from "app/components/CreditsBox";

import * as S from "app/components/FootnotesBox.style";

const FootnotesBox = ({ items, showCaptions, showCredits }) => (
  <div css={[S.footnoteBox]}>
    {showCaptions && (
      <CaptionsList
        list={items
          .map((figure) => _get(figure, `figure[0].captionTitle`))
          .filter((f) => f)}
      />
    )}
    {showCredits && <CreditsBox css={S.topGap} items={items} />}
  </div>
);

export default FootnotesBox;
