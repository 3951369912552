import React from "react";
import IconPlus from "app/components/icons/IconPlus";

import * as S from "app/components/MoreBtn.style";

const MoreBtn = ({ className, pin, invert }) => (
  <div
    className={className}
    css={[
      S.flex,
      S.flexCenterAll,
      S.btnSize,
      pin && S.pinBotRight,
      invert && S.invert,
    ]}
  >
    <IconPlus css={S.icon} />
  </div>
);

export default MoreBtn;
