import React, { useEffect, useState } from "react";
import _get from "lodash.get";
import { Global } from "@emotion/react";

// import GalleryButton from "app/components/GalleryButton";
import SideNav from "app/components/SideNav";
import SubPage from "app/components/SubPage";
import Typography from "app/components/Typography";

import useNavData from "app/hooks/useNav";
// import useSitePages from "app/hooks/useSitePages";
import { useActiveSectionWindow } from "app/hooks/useActiveSection";

import * as S from "app/components/DetailPage.style";
import { css } from "@emotion/react";

const PageSpacer = (props) => {
  const [spacerH, setSpacerH] = useState(0);

  return (
    <>
      <SubPage {...props} setSpacerH={setSpacerH} />
      <div
        css={css`
          height: ${spacerH}px;
        `}
      />
    </>
  );
};

export const PageSection = ({ sectionTitle, pages, idx }) => (
  <section css={[S.relative, S.w100]} data-section={idx} id={`section-${idx}`}>
    <div id={`section-${idx}-anchor`} css={[S.absolute, S.anchorLink]} />
    <Typography variant={"h2"} css={[S.sectionTitle, S.sectionMargin]}>
      {sectionTitle}
    </Typography>
    {pages.map((entry) => (
      <PageSpacer key={_get(entry, "id")} entry={entry} />
    ))}
  </section>
);

export const PageWrapper = ({ children }) => (
  <>
    <Global styles={S.detailGlobal} />
    <div css={[S.gridContent, S.padTopGap]}>{children}</div>
  </>
);

const FormPage = ({ data }) => {
  const { setNavItems } = useNavData();
  // const { baseUri } = useSitePages();

  const sections = _get(data, "entry.exploration") || [];
  const activeSection = useActiveSectionWindow();
  const activeSectionName = _get(sections, `[${activeSection}].sectionTitle`);
  const title = _get(data, "entry.displayTitle") || _get(data, "entry.title");

  useEffect(() => {
    setNavItems(undefined);
  }, [setNavItems]);

  useEffect(() => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "explore-section-viewed",
        pageTitle: title,
        entryId: _get(data, "entry.id"),
        sectionName: activeSectionName,
      });
    }
  }, [activeSectionName, title]);

  return (
    <>
      <SideNav
        items={sections.map(({ sectionTitle }, idx) => ({
          title: sectionTitle,
          active: activeSection === idx,
          onClick: () => {
            const sectionEl = document.getElementById(`section-${idx}-anchor`);
            if (sectionEl) {
              sectionEl.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }
          },
        }))}
        // Extra={
        //   !about && (
        //     <GalleryButton
        //       title={`${_get(data, "entry.formgallery[0].title")} Gallery`}
        //       images={_get(data, "entry.formgallery[0].galleryPreviewImages")}
        //       link={
        //         baseUri
        //           ? `/${baseUri}/${_get(data, "entry.formgallery[0].uri")}/`
        //           : `/${_get(data, "entry.formgallery[0].uri")}/`
        //       }
        //     />
        //   )
        // }
      />
      <PageWrapper>
        <Typography variant="h1" css={[S.title]}>
          {title}
        </Typography>
        {sections.map((props, idx) => (
          <PageSection key={idx} idx={idx} {...props} />
        ))}
        <div style={{ height: "25vh" }} />
      </PageWrapper>
    </>
  );
};

export default FormPage;
