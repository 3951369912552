import React from "react";
import _get from "lodash.get";

import Image from "app/components/Image";
import RatioBox from "app/components/RatioBox";

import * as S from "app/components/RatioImage.style";

const RatioImage = ({ ratio, bgColor, figure, total, num, showCaptions }) => (
  <RatioBox ratio={ratio} fit bgColor={bgColor}>
    {showCaptions && total > 1 && (
      <div css={S.number}>{`${num}`.padStart(2, "0")}</div>
    )}
    {_get(figure, "[0].kind") === "video" && (
      <video css={S.contain} playsInline autoPlay muted loop>
        <source
          src={_get(figure, "[0].url")}
          mimetype={_get(figure, "[0].mimeType")}
        />
      </video>
    )}
    {_get(figure, "[0].kind") === "image" && (
      <Image
        css={_get(figure, "[0].mimeType").includes("gif") ? S.contain : S.cover}
        image={figure}
        bgColor={bgColor}
        objectFit="contain"
        objectPosition="50% 50%"
        imgStyle={{
          objectFit: "contain",
          objectPosition: "50% 50%",
        }}
        alt=""
      />
    )}
  </RatioBox>
);

export default RatioImage;
