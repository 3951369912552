import React from "react";
import _get from "lodash.get";

import { blur } from "app/components/Button";
import IconSlideshow from "app/components/icons/IconSlideshow";
import Image from "app/components/Image";
import MoreBtn from "app/components/MoreBtn";
import GalleryImage from "app/components/GalleryImage";
import GalleryModalList from "app/components/GalleryModalList";
import RedactorText from "app/components/RedactorText";
import Typography from "app/components/Typography";

import * as S from "app/components/ThumbnailGallery.style";

const ThumbnailItem = ({ image, label, sublabel, id, onClick }) => (
  <button
    css={[S.buttonBox, S.p4, S.flexCol, S.relative]}
    key={id}
    onMouseDown={blur}
    onClick={onClick}
  >
    <GalleryImage image={image} size={250} more />
    <Typography m0 variant="subheading" weight="semiBold">
      {label}
    </Typography>
    {sublabel && (
      <Typography m0 variant="subheading" weight="thin">
        {sublabel}
      </Typography>
    )}
    <MoreBtn pin />
  </button>
);

const ThumbList = ({ images, setActiveId }) => (
  <div css={[S.thumbList, S.w100]}>
    {images.map((item) => (
      <ThumbnailItem
        key={item.id}
        {...item}
        onClick={() => setActiveId(item.id)}
      />
    ))}
  </div>
);

const ThumbnailModal = ({ body, label, sublabel, count, countTotal }) => (
  <>
    <Typography variant="galleryTitle">
      {label}
      {sublabel && (
        <>
          <br />
          <span style={{ fontWeight: 300 }}>{sublabel}</span>
        </>
      )}
    </Typography>
    <Typography m0 as="p" variant="h2">
      <b>{count}</b> / {countTotal}
    </Typography>
    <RedactorText html={body} />
  </>
);

const SlideshowGalery = ({ image, images, setActiveId }) => (
  <button
    css={[S.textButton, S.p2, S.border, S.borderRound]}
    onMouseDown={blur}
    onClick={() => setActiveId(_get(images, "[0].id"))}
  >
    <Image image={image} />
    <div css={[S.flex, S.alignCenter, S.p4]}>
      <Typography m0 variant="body">
        Explore Gallery
      </Typography>
      <IconSlideshow css={S.slideshowIcon} />
      <Typography m0 variant="body">
        {images.length}
      </Typography>
    </div>
  </button>
);

const ModalImage = ({ fullImage }) => <GalleryImage image={fullImage} />;

const ThumbnailGallery = ({ variant, items, slideshowImage }) => {
  const List =
    variant === "slideshow" ? (
      <SlideshowGalery image={slideshowImage} />
    ) : (
      <ThumbList />
    );

  return (
    <GalleryModalList
      List={List}
      label={"Back to About"}
      gallery={items}
      ModalImage={<ModalImage />}
      ModalContent={<ThumbnailModal />}
    />
  );
};

export default ThumbnailGallery;
