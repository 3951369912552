import { useMemo, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import _get from "lodash.get";

const canMeasure =
  typeof window !== "undefined" && typeof window.ResizeObserver !== "undefined";

const useBoundingBox = () => {
  const [element, ref] = useState(null);
  const [rect, setRect] = useState({ top: 0, left: 0, bottom: 0, right: 0 });

  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        const target = _get(entries, "[0].target");
        if (target) {
          const { top, left, bottom, right } = target.getBoundingClientRect();
          setRect({ top, left, bottom, right });
        }
      }),
    []
  );

  useIsomorphicLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, rect];
};
export default canMeasure ? useBoundingBox : () => [() => undefined, {}];
