import { ratioValues } from "app/components/RatioBox.style";

export const getGridLayout = (key, items) =>
  (key === "single" && layouts2["single"][0]) ||
  layouts2[key].find(({ itemsMatch }) => !itemsMatch || itemsMatch(items)) ||
  layouts2["double"][0];

export const isTall = ({ ratio }) => ratioValues[ratio] > 1;

const layouts2 = {
  single: [
    {
      colCount: 1,
      limit: 2,
    },
  ],
  double: [
    {
      colCount: 2,
      limit: 6,
    },
  ],
  mixedH: [
    {
      itemsMatch: (items) => !isTall(items[0]),
      colCount: 2,
      limit: 3,
      cols: [
        [1, 3],
        [1, 2],
        [2, 3],
      ],
      rows: [1, 2, 2],
    },
    {
      itemsMatch: (items) => isTall(items[0]),
      colCount: 2,
      limit: 3,
      cols: [
        [1, 2],
        [2, 3],
        [1, 3],
      ],
      rows: [1, 1, 2],
    },
  ],
  mixedV: [
    {
      itemsMatch: (n) => n <= 2,
      colCount: 1,
      limit: 2,
    },
    {
      itemsMatch: (items) => items.length === 3 && isTall(items[0]),
      colCount: 2,
      limit: 3,
      rows: [
        [1, 3],
        [1, 2],
        [2, 3],
      ],
      cols: [1, 2, 2],
      top: 0,
    },
    {
      itemsMatch: (items) => items.length === 3 && !isTall(items[0]),
      colCount: 2,
      limit: 3,
      rows: [
        [1, 2],
        [2, 3],
        [1, 3],
      ],
      cols: [1, 1, 2],
      top: 2,
    },
    {
      itemsMatch: (items) => items.length >= 4 && isTall(items[0]),
      colCount: 2,
      limit: 4,
      rows: [
        [1, 3],
        [1, 2],
        [3, 4],
        [2, 4],
      ],
      cols: [1, 2, 1, 2],
    },
    {
      itemsMatch: (items) => items.length >= 4 && !isTall(items[0]),
      colCount: 2,
      limit: 4,
      rows: [
        [1, 2],
        [1, 3],
        [2, 4],
        [3, 4],
      ],
      cols: [1, 2, 1, 2],
    },
  ],
};

export default layouts2;
